@use "sass:map";

$header-top: calc(0.5em + 1.5vw);

$grid-side-margin: calc(1rem + 2vw);
$grid-cell-border-radius: calc(0.55rem + 0.4vw);
$grid-cell-padding: 1.5em;

$tags-padding: 10px;
$tag-padding: 0.75em;
$tag-font-size: 1.5em;

// header
.header {
  width: 83vw;
  height: fit-content;
  position: sticky;
  top: $header-top;

  pointer-events: none;
}

h1.header-placeholder {
  opacity: 0;
  position: relative;
  height: fit-content;
  box-sizing: border-box;
}

@mixin color-child($index, $color) {
  :nth-child(#{$index}) {
    color: $color;
    text-shadow: 0 0 25px $color;
  }
}

.title {
  position: absolute;
  top: 0;
  margin: 0;
  font-family: $font-tiny;
  line-height: 65%;
  font-weight: 150;
  text-transform: uppercase;

  $color: $default-color;
  color: $color;
  mix-blend-mode: screen;
  text-shadow: 0 0 25px $color;
}

.website-title {
  padding: 0 1.5vw 0 $grid-side-margin;
  font-size: calc(3.5em + 10vw);
}

// grid
.grid {
  display: grid;
  $grid-padding: calc(0.6em + 0.5vw);
  row-gap: $grid-padding;
  column-gap: $grid-padding;
  margin: calc($header-top * 3.5) $grid-side-margin;
}

$grid-template-columns: (
  "small": 1fr,
  "medium": repeat(2, 1fr),
  "large": repeat(3, 1fr),
  "exLarge": repeat(4, 1fr),
);

@include viewport(".grid", "grid-template-columns", $grid-template-columns);

.grid-cell {
  height: 100%;
  aspect-ratio: 1;
  position: relative;

  border: black solid 2px;
  border-radius: $grid-cell-border-radius;
  background-color: black;
  box-shadow: 0 0 calc(0.6rem + 0.1vw) 1px $box-shadow;
  color: white;

  z-index: 3;
}

@media (hover) {
  .grid-cell:hover .grid-cell-text {
    opacity: 1;
  }
}

@media (hover: none) {
  .grid-cell-text {
    pointer-events: none;
  }
}

.grid-cell-text {
  width: 100%;
  height: 100%;
  position: absolute;
  padding: $grid-cell-padding;
  box-sizing: border-box;

  background-color: inherit;
  border-radius: $grid-cell-border-radius;
  opacity: 0;
  transition: opacity 0.15s;

  z-index: 2;
}

.grid-cell-name {
  margin: 0;
  padding-bottom: 0.75em;

  font-style: italic;
}

@include viewport(".grid-cell-name", "font-size", $cell-name-sizes);

.grid-cell-title {
  margin: 0;
  position: relative;
  top: -12px;

  line-height: 118%;
  text-transform: uppercase;
}

@include viewport(".grid-cell-title", "font-size", $cell-title-sizes);

.grid-cell-tags {
  position: absolute;
  bottom: $grid-cell-padding;
  left: 0;
  padding: 0 $grid-cell-padding;
}

.grid-cell-tag {
  width: fit-content;
  margin: $tag-margin;
  padding: 0.05em 0.5em 0.1em;
  display: inline-block;

  border: 1.5px white solid;

  &:hover,
  &.selected {
    @include black-on-white;
  }
}

@include viewport(".grid-cell-tags", "font-size", $cell-tag-sizes);

.grid-cell-image {
  width: 100%;
  height: auto;
  aspect-ratio: 1;
  position: relative;

  object-fit: cover;
  border-radius: $grid-cell-border-radius;
}

//tag
.tag-container-expanded {
  &.tag-container {
    border: black solid 1px;
    background-color: white;

    & .tag-header {
      border: none;
      width: calc(100% - $tag-padding * 2.5 - 2px);
    }

    & .tags {
      display: initial;
    }
  }
}

.tag-container {
  width: calc(11rem + 10vw);
  margin: $header-top $grid-side-margin;
  position: fixed;
  top: 0;
  right: 0;
  box-sizing: border-box;

  display: flex;
  align-items: flex-end;
  flex-direction: column;

  z-index: 3;
}

@include viewport(".tag-container", "font-size", $cell-tag-sizes);

.tag-header {
  height: $tag-font-size;
  padding: $tag-padding * 0.3 $tag-padding * 1.25 $tag-padding;

  display: flex;
  align-items: baseline;
  justify-content: space-between;

  font-size: 135%;
  border: 1px black solid;
  background-color: white;
}

.tag-toggle {
  height: 100%;
  width: fit-content;
  text-align: right;

  &:only-child {
    margin-left: auto;
  }
}

.tag-clear {
  height: 100%;
  font-size: 75%;
  color: $clear-gray;
}

.tags {
  padding: $tag-padding;
  padding-bottom: $tag-padding * 2;
  display: none;
  z-index: 4;
}

.tag {
  width: fit-content;
  margin: $tag-margin;
  padding: 0.05em 0.5em 0.1em;

  display: inline-block;

  background-color: white;
  border: 1.5px black solid;

  &.selected {
    background-color: black;
    color: white;
  }
}

.tags-mobile {
  position: relative;
  padding: $tag-padding $tag-padding $tag-padding * 2;
  z-index: 4;
}

@media only screen and (max-width: map.get($breakpoints, "small")) {
  .grid {
    grid-gap: 10px;
  }

  .tags {
    width: 100%;
    padding: $tags-padding;
    position: fixed;

    display: none;

    background-color: white;
    z-index: 4;
  }
}

.grid-cell,
.tag-toggle,
.tag-clear,
.tag {
  cursor: pointer;
}

.header,
.grid-cell-tags {
  user-select: none;
}
