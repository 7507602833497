@use "sass:map";

$font-tiny: "Tiny, Helvetica, Arial, Sans-Serif";
$font-routed: "Routed Gothic, Helvetica, Arial, Sans-Serif";
$font-chinese: "Yuanti SC";

@font-face {
  font-family: $font-tiny;
  src: url("../../public/fonts/TINY5x3GX.ttf") format("truetype");
  font-style: normal;
}

@font-face {
  font-family: $font-routed;
  src: url("../../public/fonts/routed-gothic.ttf") format("truetype");
  font-style: normal;
}

@font-face {
  font-family: $font-tiny;
  src: url("../../public/fonts/routed-gothic-italic.ttf") format("truetype");
  font-style: italic;
}

:lang(zh) {
  font-family: $font-chinese;
}

$description-font-size: calc(0.8em + 1vw);

$cell-name-sizes: (
  "small": calc(0.6rem + 1vw),
  "medium": calc(0.7rem + 0.6vw),
  "large": calc(0.5rem + 0.8vw),
  "exLarge": calc(0.5rem + 0.5vw),
);

$cell-title-sizes: (
  "small": calc(1rem + 3vw),
  "medium": calc(0.85rem + 1.7vw),
  "large": calc(0.5rem + 1.7vw),
  "exLarge": calc(0.5rem + 1.3vw),
);

$cell-tag-sizes: (
  "small": calc(0.25rem + 2vw),
  "medium": calc(0.5rem + 0.75vw),
  "large": calc(0.25rem + 0.7vw),
  "exLarge": calc(0.2rem + 0.62vw),
);

$project-title-sizes: (
  "small": calc(2.25rem + 1.5vw),
  "medium": calc(0.8rem + 1.5vw),
  "large": calc(1rem + 1.3vw),
  "exLarge": calc(1rem + 1.5vw),
);

$project-brief-sizes: (
  "small": calc(1rem + 0.5vw),
  "medium": calc(0.7rem + 0.5vw),
  "large": calc(0.7rem + 0.5vw),
  "exLarge": calc(0.5rem + 0.6vw),
);

$project-bio-sizes: (
  "small": calc(0.85rem + 0.4vw),
  "medium": calc(0.6rem + 0.4vw),
  "large": calc(0.6rem + 0.4vw),
  "exLarge": calc(0.5rem + 0.5vw),
);

$project-student-name-sizes: (
  "small": calc(1em + 0.7vw),
  "medium": calc(1em + 0.5vw),
  "large": calc(1em + 0.5vw),
  "exLarge": calc(1em + 0.5vw),
);

$project-media-wrapper-widths: (
  "small": 100vw,
  "medium": calc(2.5rem + 55vw),
  "large": calc(2.5em + 60vw),
  "exLarge": calc(5rem + 60vw),
);

$project-instructor-sizes: (
  "small": calc(0.6rem + 0.4vw),
  "medium": calc(0.6rem + 0.4vw),
  "large": calc(0.6rem + 0.4vw),
  "exLarge": calc(0.5rem + 0.4vw),
);

@function subtractByFull($key) {
  @return calc(100vw - map.get($project-media-wrapper-widths, $key));
}

$project-text-wrapper-widths: (
  "small": 100vw,
  "medium": subtractByFull("medium"),
  "large": subtractByFull("large"),
  "exLarge": subtractByFull("exLarge"),
);

$project-media-width-percentage: 0.95;

@function getMediaWidth($key) {
  @return calc(
    map.get($project-media-wrapper-widths, $key) *
      $project-media-width-percentage
  );
}

$project-media-widths: (
  "small": 93.5%,
  "medium": getMediaWidth("medium"),
  "large": getMediaWidth("large"),
  "exLarge": getMediaWidth("exLarge"),
);

$widthToHeight: 1.7777777778;

@function getIframeHeight($key) {
  @return calc(map.get($project-media-widths, $key) / $widthToHeight);
}

$project-iframe-heights: (
  "small": getIframeHeight("small"),
  "medium": getIframeHeight("medium"),
  "large": getIframeHeight("large"),
  "exLarge": getIframeHeight("exLarge"),
);
