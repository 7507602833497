@use "sass:math";
@import "./breakpoints";
@import "./fonts";
@import "./colors";
@import "./mixins";
@import "./common";
@import "./home";
@import "./project";

body {
  margin: 0;
  padding: 0;
  font-family: $font-routed;
}
