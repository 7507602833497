@font-face {
  font-family: "Tiny, Helvetica, Arial, Sans-Serif";
  src: url("../../public/fonts/TINY5x3GX.ttf") format("truetype");
  font-style: normal;
}
@font-face {
  font-family: "Routed Gothic, Helvetica, Arial, Sans-Serif";
  src: url("../../public/fonts/routed-gothic.ttf") format("truetype");
  font-style: normal;
}
@font-face {
  font-family: "Tiny, Helvetica, Arial, Sans-Serif";
  src: url("../../public/fonts/routed-gothic-italic.ttf") format("truetype");
  font-style: italic;
}
:lang(zh) {
  font-family: "Yuanti SC";
}

.header {
  width: 83vw;
  height: fit-content;
  position: sticky;
  top: calc(0.5em + 1.5vw);
  pointer-events: none;
}

h1.header-placeholder {
  opacity: 0;
  position: relative;
  height: fit-content;
  box-sizing: border-box;
}

.title {
  position: absolute;
  top: 0;
  margin: 0;
  font-family: "Tiny, Helvetica, Arial, Sans-Serif";
  line-height: 65%;
  font-weight: 150;
  text-transform: uppercase;
  color: greenyellow;
  mix-blend-mode: screen;
  text-shadow: 0 0 25px greenyellow;
}

.website-title {
  padding: 0 1.5vw 0 calc(1rem + 2vw);
  font-size: calc(3.5em + 10vw);
}

.grid {
  display: grid;
  row-gap: calc(0.6em + 0.5vw);
  column-gap: calc(0.6em + 0.5vw);
  margin: calc((0.5em + 1.5vw) * 3.5) calc(1rem + 2vw);
}

.grid {
  grid-template-columns: repeat(3, 1fr);
}

@media only screen and (max-width: 1024px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 768px) {
  .grid {
    grid-template-columns: 1fr;
  }
}
@media only screen and (min-width: 1500px) {
  .grid {
    grid-template-columns: repeat(4, 1fr);
  }
}
.grid-cell {
  height: 100%;
  aspect-ratio: 1;
  position: relative;
  border: black solid 2px;
  border-radius: calc(0.55rem + 0.4vw);
  background-color: black;
  box-shadow: 0 0 calc(0.6rem + 0.1vw) 1px #525252;
  color: white;
  z-index: 3;
}

@media (hover) {
  .grid-cell:hover .grid-cell-text {
    opacity: 1;
  }
}
@media (hover: none) {
  .grid-cell-text {
    pointer-events: none;
  }
}
.grid-cell-text {
  width: 100%;
  height: 100%;
  position: absolute;
  padding: 1.5em;
  box-sizing: border-box;
  background-color: inherit;
  border-radius: calc(0.55rem + 0.4vw);
  opacity: 0;
  transition: opacity 0.15s;
  z-index: 2;
}

.grid-cell-name {
  margin: 0;
  padding-bottom: 0.75em;
  font-style: italic;
}

.grid-cell-name {
  font-size: calc(0.5rem + 0.8vw);
}

@media only screen and (max-width: 1024px) {
  .grid-cell-name {
    font-size: calc(0.7rem + 0.6vw);
  }
}
@media only screen and (max-width: 768px) {
  .grid-cell-name {
    font-size: calc(0.6rem + 1vw);
  }
}
@media only screen and (min-width: 1500px) {
  .grid-cell-name {
    font-size: calc(0.5rem + 0.5vw);
  }
}
.grid-cell-title {
  margin: 0;
  position: relative;
  top: -12px;
  line-height: 118%;
  text-transform: uppercase;
}

.grid-cell-title {
  font-size: calc(0.5rem + 1.7vw);
}

@media only screen and (max-width: 1024px) {
  .grid-cell-title {
    font-size: calc(0.85rem + 1.7vw);
  }
}
@media only screen and (max-width: 768px) {
  .grid-cell-title {
    font-size: calc(1rem + 3vw);
  }
}
@media only screen and (min-width: 1500px) {
  .grid-cell-title {
    font-size: calc(0.5rem + 1.3vw);
  }
}
.grid-cell-tags {
  position: absolute;
  bottom: 1.5em;
  left: 0;
  padding: 0 1.5em;
}

.grid-cell-tag {
  width: fit-content;
  margin: 0.5em;
  padding: 0.05em 0.5em 0.1em;
  display: inline-block;
  border: 1.5px white solid;
}
.grid-cell-tag:hover, .grid-cell-tag.selected {
  background-color: white;
  color: black;
}

.grid-cell-tags {
  font-size: calc(0.25rem + 0.7vw);
}

@media only screen and (max-width: 1024px) {
  .grid-cell-tags {
    font-size: calc(0.5rem + 0.75vw);
  }
}
@media only screen and (max-width: 768px) {
  .grid-cell-tags {
    font-size: calc(0.25rem + 2vw);
  }
}
@media only screen and (min-width: 1500px) {
  .grid-cell-tags {
    font-size: calc(0.2rem + 0.62vw);
  }
}
.grid-cell-image {
  width: 100%;
  height: auto;
  aspect-ratio: 1;
  position: relative;
  object-fit: cover;
  border-radius: calc(0.55rem + 0.4vw);
}

.tag-container-expanded.tag-container {
  border: black solid 1px;
  background-color: white;
}
.tag-container-expanded.tag-container .tag-header {
  border: none;
  width: calc(100% - 1.875em - 2px);
}
.tag-container-expanded.tag-container .tags {
  display: initial;
}

.tag-container {
  width: calc(11rem + 10vw);
  margin: calc(0.5em + 1.5vw) calc(1rem + 2vw);
  position: fixed;
  top: 0;
  right: 0;
  box-sizing: border-box;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  z-index: 3;
}

.tag-container {
  font-size: calc(0.25rem + 0.7vw);
}

@media only screen and (max-width: 1024px) {
  .tag-container {
    font-size: calc(0.5rem + 0.75vw);
  }
}
@media only screen and (max-width: 768px) {
  .tag-container {
    font-size: calc(0.25rem + 2vw);
  }
}
@media only screen and (min-width: 1500px) {
  .tag-container {
    font-size: calc(0.2rem + 0.62vw);
  }
}
.tag-header {
  height: 1.5em;
  padding: 0.225em 0.9375em 0.75em;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  font-size: 135%;
  border: 1px black solid;
  background-color: white;
}

.tag-toggle {
  height: 100%;
  width: fit-content;
  text-align: right;
}
.tag-toggle:only-child {
  margin-left: auto;
}

.tag-clear {
  height: 100%;
  font-size: 75%;
  color: #c1c1c1;
}

.tags {
  padding: 0.75em;
  padding-bottom: 1.5em;
  display: none;
  z-index: 4;
}

.tag {
  width: fit-content;
  margin: 0.5em;
  padding: 0.05em 0.5em 0.1em;
  display: inline-block;
  background-color: white;
  border: 1.5px black solid;
}
.tag.selected {
  background-color: black;
  color: white;
}

.tags-mobile {
  position: relative;
  padding: 0.75em 0.75em 1.5em;
  z-index: 4;
}

@media only screen and (max-width: 768px) {
  .grid {
    grid-gap: 10px;
  }
  .tags {
    width: 100%;
    padding: 10px;
    position: fixed;
    display: none;
    background-color: white;
    z-index: 4;
  }
}
.grid-cell,
.tag-toggle,
.tag-clear,
.tag {
  cursor: pointer;
}

.header,
.grid-cell-tags {
  user-select: none;
}

.project-content {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}
@media only screen and (max-width: 768px) {
  .project-content {
    overflow: initial;
    flex-direction: column;
  }
}
.project-content > div {
  flex: none;
}

.project-header-container {
  width: 100vw;
  height: calc(2rem + 3.5vw);
  position: relative;
  box-sizing: border-box;
  padding-left: calc(1em + 3vw);
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 768px) {
  .project-header-container {
    height: calc(3rem + 5vw);
  }
}

.project-header-wrapper {
  position: relative;
  cursor: pointer;
  z-index: 2;
}

.project-header {
  font-size: calc(0.9em + 2.25vw);
  font-weight: 50;
  text-shadow: 0 0 5px greenyellow;
  letter-spacing: 1px;
}
@media only screen and (max-width: 768px) {
  .project-header {
    font-size: calc(2rem + 5vw);
  }
}

.project-media-scroll-wrapper,
.project-text-scroll-wrapper {
  overflow-y: scroll;
  position: sticky;
  top: 0;
  padding-top: calc(2rem + 3.5vw);
}
@media only screen and (max-width: 768px) {
  .project-media-scroll-wrapper,
.project-text-scroll-wrapper {
    padding-top: calc(3rem + 5vw);
    position: initial;
  }
}

.project-text-scroll-wrapper {
  width: calc(100vw - (2.5em + 60vw));
}

@media only screen and (max-width: 1024px) {
  .project-text-scroll-wrapper {
    width: calc(100vw - (2.5rem + 55vw));
  }
}
@media only screen and (max-width: 768px) {
  .project-text-scroll-wrapper {
    width: 100vw;
  }
}
@media only screen and (min-width: 1500px) {
  .project-text-scroll-wrapper {
    width: calc(100vw - (5rem + 60vw));
  }
}
@media only screen and (max-width: 768px) {
  .project-media-scroll-wrapper {
    padding-top: calc(1.5rem + 2vw);
  }
}

.project-text-wrapper {
  padding: 0 calc(1em + 3vw);
}
@media only screen and (max-width: 768px) {
  .project-text-wrapper {
    display: flex;
    flex-direction: column;
  }
}

.project-title {
  margin: 0 0 2.5%;
  width: 95%;
  font-style: italic;
  letter-spacing: -1px;
  line-height: 115%;
  text-transform: uppercase;
}
@media only screen and (max-width: 768px) {
  .project-title {
    margin: 0 0 4%;
    line-height: 125%;
  }
}

.project-title {
  font-size: calc(1rem + 1.3vw);
}

@media only screen and (max-width: 1024px) {
  .project-title {
    font-size: calc(0.8rem + 1.5vw);
  }
}
@media only screen and (max-width: 768px) {
  .project-title {
    font-size: calc(2.25rem + 1.5vw);
  }
}
@media only screen and (min-width: 1500px) {
  .project-title {
    font-size: calc(1rem + 1.5vw);
  }
}
.project-tags {
  margin-bottom: 3.5%;
  position: relative;
  left: -0.5em;
}
.project-tags > .tag:hover {
  background-color: black;
  color: white;
}

.project-brief {
  line-height: 1.25em;
  hyphens: auto;
}

.project-brief {
  font-size: calc(0.7rem + 0.5vw);
}

@media only screen and (max-width: 1024px) {
  .project-brief {
    font-size: calc(0.7rem + 0.5vw);
  }
}
@media only screen and (max-width: 768px) {
  .project-brief {
    font-size: calc(1rem + 0.5vw);
  }
}
@media only screen and (min-width: 1500px) {
  .project-brief {
    font-size: calc(0.5rem + 0.6vw);
  }
}
.student-info {
  width: 108%;
  padding: 4%;
  margin-top: 4%;
  position: relative;
  left: -4%;
  box-sizing: border-box;
  border-radius: 0.7vw;
  background-color: black;
  color: white;
}
@media only screen and (max-width: 768px) {
  .student-info {
    width: 93.5vw;
    padding: 5%;
    margin-top: initial;
    left: 0;
    align-self: center;
    border-radius: 2vw;
  }
}

.student-bio {
  padding: 0;
  margin-top: 0.5em;
  font-style: italic;
  line-height: 1.35em;
}

.student-email {
  margin: 0.25em 0 1.5em 0;
}
.student-email a {
  color: white;
}
@media only screen and (max-width: 768px) {
  .student-email {
    margin: 0.075em 0 0.75em 0;
  }
}

.student-bio {
  font-size: calc(0.6rem + 0.4vw);
}

@media only screen and (max-width: 1024px) {
  .student-bio {
    font-size: calc(0.6rem + 0.4vw);
  }
}
@media only screen and (max-width: 768px) {
  .student-bio {
    font-size: calc(0.85rem + 0.4vw);
  }
}
@media only screen and (min-width: 1500px) {
  .student-bio {
    font-size: calc(0.5rem + 0.5vw);
  }
}
.project-media-scroll-wrapper {
  width: calc(2.5em + 60vw);
}

@media only screen and (max-width: 1024px) {
  .project-media-scroll-wrapper {
    width: calc(2.5rem + 55vw);
  }
}
@media only screen and (max-width: 768px) {
  .project-media-scroll-wrapper {
    width: 100vw;
  }
}
@media only screen and (min-width: 1500px) {
  .project-media-scroll-wrapper {
    width: calc(5rem + 60vw);
  }
}
.project-media-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}

.project-media {
  margin-bottom: 1%;
  border: 1px solid black;
  border-radius: 0.5vw;
  box-shadow: 0 0 15px 2px #525252;
}
@media only screen and (max-width: 768px) {
  .project-media {
    margin-bottom: 3%;
    border-radius: 2vw;
    box-shadow: 0 0 7px 2px #525252;
  }
}
.project-media:last-child {
  margin-bottom: calc(1em + 1vw);
}

.project-image {
  cursor: zoom-in;
  display: none;
}

.image-zoom {
  pointer-events: none;
  opacity: 0;
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.9);
  cursor: zoom-out;
  transition: opacity 0.1s;
}
.image-zoom.shown {
  pointer-events: all;
  opacity: 1;
}
.image-zoom > img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.project-iframe-youtube {
  aspect-ratio: 1.7777778;
}

.project-iframe-vimeo {
  aspect-ratio: 1.75;
}

.project-media {
  width: calc((2.5em + 60vw) * 0.95);
}

@media only screen and (max-width: 1024px) {
  .project-media {
    width: calc((2.5rem + 55vw) * 0.95);
  }
}
@media only screen and (max-width: 768px) {
  .project-media {
    width: 93.5%;
  }
}
@media only screen and (min-width: 1500px) {
  .project-media {
    width: calc((5rem + 60vw) * 0.95);
  }
}
.project-iframe-unknown {
  height: calc((2.5em + 60vw) * 0.95 / 1.7777777778);
}

@media only screen and (max-width: 1024px) {
  .project-iframe-unknown {
    height: calc((2.5rem + 55vw) * 0.95 / 1.7777777778);
  }
}
@media only screen and (max-width: 768px) {
  .project-iframe-unknown {
    height: 52.5937499993%;
  }
}
@media only screen and (min-width: 1500px) {
  .project-iframe-unknown {
    height: calc((5rem + 60vw) * 0.95 / 1.7777777778);
  }
}
.student-name {
  margin: 0;
  height: calc(2rem + 0.5vw);
}

.student-name {
  font-size: calc(1em + 0.5vw);
}

@media only screen and (max-width: 1024px) {
  .student-name {
    font-size: calc(1em + 0.5vw);
  }
}
@media only screen and (max-width: 768px) {
  .student-name {
    font-size: calc(1em + 0.7vw);
  }
}
@media only screen and (min-width: 1500px) {
  .student-name {
    font-size: calc(1em + 0.5vw);
  }
}
.student-social-wrapper {
  height: calc(2rem + 0.5vw);
  display: flex;
  align-items: flex-end;
}
@media only screen and (max-width: 768px) {
  .student-social-wrapper {
    margin-top: calc(1.5rem + 1.5vw);
  }
}

.student-name-wrapper {
  display: flex;
  justify-content: space-between;
}

.student-social-inverted {
  fill: white;
  width: auto;
  height: calc(0.6rem + 0.6vw);
}
@media only screen and (max-width: 768px) {
  .student-social-inverted {
    height: calc(1.5rem + 2vw);
  }
}

.student-social-link {
  width: fit-content;
  margin-right: calc(0.5rem + 0.5vw);
}
@media only screen and (max-width: 768px) {
  .student-social-link {
    margin-right: calc(1rem + 0.5vw);
  }
}

.project-tags {
  font-size: calc(0.25rem + 0.7vw);
}

@media only screen and (max-width: 1024px) {
  .project-tags {
    font-size: calc(0.5rem + 0.75vw);
  }
}
@media only screen and (max-width: 768px) {
  .project-tags {
    font-size: calc(0.25rem + 2vw);
  }
}
@media only screen and (min-width: 1500px) {
  .project-tags {
    font-size: calc(0.2rem + 0.62vw);
  }
}
.student-instructors {
  margin-bottom: calc(1em + 1vw);
}

.student-instructors {
  font-size: calc(0.6rem + 0.4vw);
}

@media only screen and (max-width: 1024px) {
  .student-instructors {
    font-size: calc(0.6rem + 0.4vw);
  }
}
@media only screen and (max-width: 768px) {
  .student-instructors {
    font-size: calc(0.6rem + 0.4vw);
  }
}
@media only screen and (min-width: 1500px) {
  .student-instructors {
    font-size: calc(0.5rem + 0.4vw);
  }
}
.student-email a {
  font-size: calc(0.6rem + 0.4vw);
}

@media only screen and (max-width: 1024px) {
  .student-email a {
    font-size: calc(0.6rem + 0.4vw);
  }
}
@media only screen and (max-width: 768px) {
  .student-email a {
    font-size: calc(0.6rem + 0.4vw);
  }
}
@media only screen and (min-width: 1500px) {
  .student-email a {
    font-size: calc(0.5rem + 0.4vw);
  }
}
body {
  margin: 0;
  padding: 0;
  font-family: "Routed Gothic, Helvetica, Arial, Sans-Serif";
}

