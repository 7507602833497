$box-shadow: #525252;
$clear-gray: #c1c1c1;
$default-color: greenyellow;

@mixin black-on-white {
  background-color: white;
  color: black;
}

@mixin white-on-black {
  background-color: black;
  color: white;
}
