@use "sass:map";

@mixin viewport($selector, $prop-name, $prop-value-map) {
  #{$selector} {
    #{$prop-name}: map.get($prop-value-map, "large");
  }
  @media only screen and (max-width: map.get($breakpoints, "medium")) {
    #{$selector} {
      #{$prop-name}: map.get($prop-value-map, "medium");
    }
  }
  @media only screen and (max-width: map.get($breakpoints, "small")) {
    #{$selector} {
      #{$prop-name}: map.get($prop-value-map, "small");
    }
  }
  @media only screen and (min-width: map.get($breakpoints, "exLarge")) {
    #{$selector} {
      #{$prop-name}: map.get($prop-value-map, "exLarge");
    }
  }
}

@mixin mobile {
  @media only screen and (max-width: map.get($breakpoints, "small")) {
    @content;
  }
}
