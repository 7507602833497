$project-text-side-padding: calc(1em + 3vw);
$project-header-height: calc(2rem + 3.5vw);
$project-header-height-mobile: calc(3rem + 5vw);
$bottom-margin: calc(1em + 1vw);

.project-content {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;

  height: 100vh;
  width: 100vw;
  overflow: hidden;

  @include mobile {
    overflow: initial;
    flex-direction: column;
  }

  > div {
    flex: none;
  }
}

.project-header-container {
  width: 100vw;
  height: $project-header-height;
  position: relative;
  box-sizing: border-box;
  padding-left: $project-text-side-padding;

  display: flex;
  align-items: center;

  @include mobile {
    height: $project-header-height-mobile;
  }
}

.project-header-wrapper {
  position: relative;
  cursor: pointer;
  z-index: 2;
}

.project-header {
  font-size: calc(0.9em + 2.25vw);

  @include mobile {
    font-size: calc(2rem + 5vw);
  }

  font-weight: 50;

  text-shadow: 0 0 5px $default-color;
  letter-spacing: 1px;
}

.project-media-scroll-wrapper,
.project-text-scroll-wrapper {
  overflow-y: scroll;
  position: sticky;
  top: 0;
  padding-top: $project-header-height;

  @include mobile {
    padding-top: $project-header-height-mobile;
    position: initial;
  }
}

@include viewport(
  ".project-text-scroll-wrapper",
  "width",
  $project-text-wrapper-widths
);

.project-media-scroll-wrapper {
  @include mobile {
    padding-top: calc(1.5rem + 2vw);
  }
}

.project-text-wrapper {
  padding: 0 $project-text-side-padding;

  @include mobile {
    display: flex;
    flex-direction: column;
  }
}

.project-title {
  margin: 0 0 2.5%;
  width: 95%;
  font-style: italic;
  letter-spacing: -1px;
  line-height: 115%;
  text-transform: uppercase;

  @include mobile {
    margin: 0 0 4%;
    line-height: 125%;
  }
}

@include viewport(".project-title", "font-size", $project-title-sizes);

.project-tags {
  margin-bottom: 3.5%;
  position: relative;
  left: -$tag-margin;

  > .tag:hover {
    @include white-on-black;
  }
}

.project-brief {
  line-height: 1.25em;
  hyphens: auto;
}

@include viewport(".project-brief", "font-size", $project-brief-sizes);

.student-info {
  $padding: 4%;

  width: calc(100% + $padding * 2);
  padding: $padding;
  margin-top: 4%;
  position: relative;
  left: -$padding;
  box-sizing: border-box;
  border-radius: 0.7vw;

  @include mobile {
    width: 93.5vw;
    padding: 5%;
    margin-top: initial;
    left: 0;
    align-self: center;
    border-radius: 2vw;
  }

  @include white-on-black;
}

.student-bio {
  padding: 0;
  margin-top: 0.5em;

  font-style: italic;
  line-height: 1.35em;
}

.student-email {
  margin: 0.25em 0 1.5em 0;
  & a {
    color: white;
  }

  @include mobile {
    margin: 0.075em 0 0.75em 0;
  }
}

@include viewport(".student-bio", "font-size", $project-bio-sizes);

@include viewport(
  ".project-media-scroll-wrapper",
  "width",
  $project-media-wrapper-widths
);

.project-media-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}

.project-media {
  margin-bottom: 1%;
  border: 1px solid black;
  border-radius: 0.5vw;
  box-shadow: 0 0 15px 2px $box-shadow;

  @include mobile {
    margin-bottom: 3%;
    border-radius: 2vw;
    box-shadow: 0 0 7px 2px $box-shadow;
  }

  &:last-child {
    margin-bottom: $bottom-margin;
  }
}

.project-image {
  cursor: zoom-in;
  display: none;
}

.image-zoom {
  pointer-events: none;
  opacity: 0;
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.9);
  cursor: zoom-out;

  &.shown {
    pointer-events: all;
    opacity: 1;
  }
  transition: opacity 0.1s;

  > img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}

.project-iframe-youtube {
  aspect-ratio: 1.7777778;
}

.project-iframe-vimeo {
  aspect-ratio: 1.75;
}

@include viewport(".project-media", "width", $project-media-widths);
@include viewport(".project-iframe-unknown", "height", $project-iframe-heights);

.student-name {
  margin: 0;
  height: calc(2rem + 0.5vw);
}

@include viewport(".student-name", "font-size", $project-student-name-sizes);

.student-social-wrapper {
  height: calc(2rem + 0.5vw);
  display: flex;
  align-items: flex-end;

  @include mobile {
    margin-top: calc(1.5rem + 1.5vw);
  }
}

.student-name-wrapper {
  display: flex;
  justify-content: space-between;
}

.student-social-inverted {
  fill: white;
  width: auto;
  height: calc(0.6rem + 0.6vw);

  @include mobile {
    height: calc(1.5rem + 2vw);
  }
}

.student-social-link {
  width: fit-content;
  margin-right: calc(0.5rem + 0.5vw);
  @include mobile {
    margin-right: calc(1rem + 0.5vw);
  }
}

@include viewport(".project-tags", "font-size", $cell-tag-sizes);

.student-instructors {
  margin-bottom: $bottom-margin;
}

@include viewport(
  ".student-instructors",
  "font-size",
  $project-instructor-sizes
);

@include viewport(".student-email a", "font-size", $project-instructor-sizes);
